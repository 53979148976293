<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="20">
            <el-form ref="form" :model="form" label-width="80px">
              <el-row>
                <el-col :md="8" :sm="12">
                  <el-form-item label="场馆名称">
                    <el-select
                      placeholder="请选择"
                      clearable
                      v-model="form.finalVenueId"
                      style="width: 100%"
                      @change="toSelect"
                    >
                      <el-option
                        v-for="item in venueList"
                        :key="item.id"
                        :label="item.venueName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="8" :sm="12">
                  <el-form-item label="运动项目">
                    <el-select
                      placeholder="请选择"
                      clearable
                      v-model="form.sportsProjectCode"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in sportList"
                        :key="item.sportsProjectCode"
                        :label="item.sportsProjectName"
                        :value="item.sportsProjectCode"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="8" :sm="18">
                  <el-form-item label="下单时间">
                    <el-date-picker
                      style="width: 100%"
                      clearable
                      v-model="form.range"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
          <el-col :span="4" class="text-right">
            <el-button @click="searchEvent" class="search-button">{{
              $t("search")
            }}</el-button>
            <el-button @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
        <el-row class="search-statue">
          <div
            style="
              display: inline-block;
              color: #606266;
              margin-left: 10px;
              margin-right: 10px;
              font-size: 14px;
            "
          >
            订单状态
          </div>
          <div
            style="display: inline-block; margin: 0 10px"
            v-for="(item, index) in statusData"
            :key="index"
          >
            <el-button
              style="border: 1px solid #439bff; background: #439bff"
              :class="{ unchose: form.orderStatusText !== item }"
              @click="tabClick(index)"
              round
              >{{ item }}</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="tabs-container">
      <el-tabs
        class="taps-card"
        v-model="form.orderType"
        @tab-click="searchEvent"
      >
        <el-tab-pane label="全部" name="0"></el-tab-pane>
        <el-tab-pane label="预约" name="1"></el-tab-pane>
        <el-tab-pane label="购票" name="2"></el-tab-pane>
        <el-tab-pane label="课程" name="3"></el-tab-pane>
        <el-tab-pane label="赛事" name="4"></el-tab-pane>
        <el-tab-pane label="会员卡" name="6"></el-tab-pane>
        <!-- 5---视频课程 -->
      </el-tabs>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
      >
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column
          prop="venueName"
          align="center"
          label="场馆名称"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="sportsProjectName"
          align="center"
          label="运动项目"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderType"
          align="center"
          label="订单类型"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderNum"
          label="订单号"
          min-width="170"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTime"
          min-width="177"
          label="下单时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="payTime"
          label="支付时间"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customNam"
          label="客户姓名"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customPhone"
          min-width="100"
          label="联系电话"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="payType"
          min-width="100"
          label="支付类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.payType | payTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="realPay"
          min-width="100"
          label="支付金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderStatus"
          label="订单状态"
          min-width="130"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.orderStatus === '待使用'"
              style="color: #eaa92e"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待支付'"
              style="color: #d2514b"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已完成'"
              style="color: #439bff"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待评价'"
              style="color: #47dbc6"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              style="color: rgb(62, 103, 174)"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消(逾期)'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-wrapper">
      <div class=""></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import PlatformClass from "./platformDetailClass";
import mixin from "@/mixins/index";
import { mapState } from "vuex";
import apis from "@/apis";

export default {
  mixins: [mixin],
  data() {
    return {
      form: new PlatformClass("form"), // 实例化一个表单的变量
      range: [],
      sportList: [],
      statusData: ["已支付", "已退款"],
      tableData: [],
    };
  },
  filters: {
    payTypeFilter(status) {
      const statusMap = {
        1: "微信",
        5: "线下-微信",
        3: "会员卡付",
        4: "线下-支付宝",
        2: "线下-现金",
      };
      return statusMap[status];
    },
  },
  computed: {
    ...mapState(["Language", "venueId", "venueList"]),
  },
  activated() {
    if (this.$route.query.venueId) {
      this.form.finalVenueId = this.$route.query.venueId;
      this.getSportList(this.form.finalVenueId);
    }
    if (this.$route.query.type) {
      this.form.orderType = this.$route.query.type;
    }
    if (this.$route.query.date) {
      this.form.range = JSON.parse(this.$route.query.date);
    }
  },
  mounted() {},
  methods: {
    sureEvent() {
      this.searchData();
    },
    toSelect(value) {
      if (value) {
        this.getSportList(value);
      }
    },
    getSportList(id) {
      this.$http
        .get(`${apis.getSportsProjectListById}?id=${id}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    tabClick(index) {
      if (index === 0) {
        this.form.searchStatusType = "2";
      } else {
        this.form.searchStatusType = "4";
      }
      this.form.orderStatusText = this.statusData[index];
      this.searchData();
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new PlatformClass("form");
      this.range = "";
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      if (this.form.range) {
        if (this.form.range.length > 0) {
          this.form.orderStTime = this.form.range[0];
          this.form.orderEndTime = this.form.range[1];
        }
      } else {
        this.form.orderStTime = "";
        this.form.orderEndTime = "";
      }
      this.tableData = [];
      this.$http.post(apis.queryOrderManagementList, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        } else if (res.data.code === 500) {
          this.tableData = [];
          this.form.total = 0;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/mixin.scoped.less";
.tabs-container {
  margin: 0 20px;
}
.unchose {
  background: #fff !important;
  color: #439bff !important;
}
.sumClass {
  background: rgba(238, 240, 244, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  // margin-bottom: 10px;
  .left {
  }
  .right {
  }
}
</style>
<style lang="less">
@import "../../assets/styles/mixin.less";
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-top: 5px;
}
.search-container .el-button {
  // background: #3E67AE !important;
}
.button-container {
  text-align: left;
  .default-btn {
    // float: left !important;
  }
}
.table-container {
  width: calc(100% - 30px);
  margin: 0px auto;
  thead {
    tr {
      th {
        background: #eef0f4 !important;
      }
    }
  }
}
.search-statue {
  .el-button {
    // background: #3E67AE !important;
  }
}
</style>
